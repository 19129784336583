<div class="card shadow-sm max-height">
  <div class="card-body pb-5">
    <h3>
      <strong>{{ BRAND_NAME }}</strong>
    </h3>
    <div class="my-5">
      <img class="img-fluid" src="assets/icons/info.svg" width="48px" alt="info icon"/>
    </div>
    <p class="mt-3 mb-3">
      Mit {{ BRAND_NAME }} können Sie bei hunderten Partnerhändlern bezahlen.
      Probieren sie es aus.
    </p>
    <a class="par-card-link">{{ BRAND_NAME }}</a>
  </div>
</div>
