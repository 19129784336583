import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ErrorStateMatcher, MatNativeDateModule, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {RouterModule} from '@angular/router';
import {CalculatorComponent} from '@app/shared/calculator/calculator.component';
import {ChangeRateComponent} from '@app/shared/calculator/change-rate/change-rate.component';
import {FocusDirective} from '@app/shared/directives/focus.directive';
import {FooterComponent} from '@app/shared/footer/footer.component';
import {TanInputComponent} from '@app/shared/form-components/tan-input.component';
import {NotReachableComponent} from '@app/shared/not-reachable/not-reachable.component';
import {CurrencyPostfixPipe} from '@app/shared/pipes/currency-postfix.pipe';
import {RequiredDisclaimerComponent} from '@app/shared/required-disclaimer/required.disclaimer.component';
import {CalculatorCardComponent} from './cards/calculator-card.component';
import {ShopFinderCardComponent} from './cards/shop-finder-card.component';
import {EqualToValidator} from './directives/equal-to-validator';
import {ErrorComponent} from './error/error.component';
import {AddressInputComponent} from './form-components/address-input.component';
import {DateInputComponent} from './form-components/date-input.component';
import {EmailInputComponent} from './form-components/email-input.component';
import {IbanInputComponent} from './form-components/iban-input.component';
import {TextInputComponent} from './form-components/text-input.component';
import {NavLogoComponent} from './nav-logo/nav-logo.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ProductHintComponent} from './product-hint/product-hint.component';
import {IbanPipe} from './pipes/iban.pipe';
import {IbanValidator} from '@app/shared/directives/iban-validator';
import {ShopfinderComponent} from './shopfinder/shopfinder.component';
import {ShowPlansComponent} from './calculator/show-plans/show-plans.component';
import {SafariSafeDatePipe} from './pipes/safari-safe-date.pipe';
import {DummyInfoCardComponent} from './dummy-info-card/dummy-info-card.component';
import {MoreInformationComponent} from './more-information/more-information.component';
import {BackendErrorComponent} from './backend-error/backend-error.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,

    MatSliderModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatIconModule,
    MatAutocompleteModule,
    GoogleMapsModule,
    NgxSliderModule,

  ],
  declarations: [
    EmailInputComponent,
    TextInputComponent,
    DateInputComponent,
    AddressInputComponent,
    IbanInputComponent,
    TanInputComponent,
    ChangeRateComponent,

    CalculatorCardComponent,
    ShopFinderCardComponent,
    CalculatorComponent,

    NavLogoComponent,
    ProductHintComponent,

    IbanPipe,
    CurrencyPostfixPipe,
    IbanValidator,
    FocusDirective,

    FooterComponent,

    NotFoundComponent,
    ErrorComponent,
    NotReachableComponent,
    ShopfinderComponent,
    ShowPlansComponent,
    RequiredDisclaimerComponent,
    EqualToValidator,
    SafariSafeDatePipe,
    DummyInfoCardComponent,
    MoreInformationComponent,
    BackendErrorComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatSliderModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatAutocompleteModule,

    ChangeRateComponent,
    EmailInputComponent,
    TextInputComponent,
    DateInputComponent,
    AddressInputComponent,
    IbanInputComponent,
    TanInputComponent,

    FocusDirective,
    IbanValidator,
    IbanPipe,
    CurrencyPostfixPipe,
    SafariSafeDatePipe,

    FooterComponent,

    CalculatorCardComponent,
    CalculatorComponent,
    ShowPlansComponent,
    ShopFinderCardComponent,
    ShopfinderComponent,

    NavLogoComponent,
    ProductHintComponent,
    RequiredDisclaimerComponent,
    EqualToValidator,
    DummyInfoCardComponent,
    MoreInformationComponent,
    BackendErrorComponent,
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ]
})
export class SharedModule {

}
