<div class=" mb-0 mt-2">
  <div class="row mx-3 mx-sm-4">
    <div class="text-left col-6 px-0 my-auto">
      <span class="calc__repay-in-label">Ich möchte zurückzahlen&nbsp;in</span>:
    </div>
    <div class="text-right col-6 px-0">
      <span class="calc__repay-in-value">
        {{ currentPlan?.duration }}&nbsp;<span class="calc__repay-in-month"
      >Monaten</span>
      </span>
    </div>
  </div>
</div>
<div class="custom-ngx-slider mx-3">
  <ngx-slider [(value)]="value" [options]="options" (userChange)="sliderValueChange($event)"></ngx-slider>
</div>
<div class="show-plans__bottom">
  <div class="show-plans__details mx-auto">
    <div class="row mx-3 mx-md-4">
      <div class="calc__details-1st-column col-6 col-sm-8 px-2 my-auto">{{ currentPlan?.duration }} <span
      >monatliche Zahlungen&nbsp;von</span>:
      </div>
      <div
          class="calc__n-monthly-payments-value col-6 col-sm-4 px-0 text-center text-sm-left">{{ currentPlan?.annuityRate | currencyPostfix }}
      </div>
    </div>
    <div class="row">
      <hr class="calc__details-line col-9"/>
    </div>
    <div class="row mx-3 mx-md-4">
      <div class="calc__details-1st-column col-8 px-2 my-auto">
        <div><span>Nettodarlehensbetrag</span>:</div>
      </div>
      <div class="calc__details-2nd-column col-4 px-0">
        <div><span><strong>{{ currentPlan?.nominalAmount | currencyPostfix }}</strong></span></div>
      </div>
    </div>
    <div class="row mx-3 mx-md-4">
      <div class="calc__details-1st-column col-8 px-2 my-auto">
        <div><span>Gesamtbetrag</span>:</div>
      </div>
      <div class="calc__details-2nd-column col-4 px-0">
        <div><span><strong>{{ currentPlan?.totalAmount | currencyPostfix }}</strong></span></div>
      </div>
    </div>
    <div class="row mx-3 mx-md-4">
      <div class="calc__details-1st-column col-8 px-2 my-auto">
        <div><span>Sollzins (gebunden)</span>:</div>
      </div>
      <div class="calc__details-2nd-column col-4 px-0">
        <div><span><strong>{{ terms?.nominalInterest | number:'1.2-2' }}%</strong>&nbsp;p.a.</span></div>
      </div>
    </div>
    <div class="row mx-3 mx-md-4">
      <div class="calc__details-1st-column col-8 px-2 my-auto">
        <div><span>Effektivzins</span>:</div>
      </div>
      <div class="calc__details-2nd-column col-4 px-0">
        <div><span><strong>{{ currentPlan?.effectiveInterest | number:'1.2-2' }}%</strong>&nbsp;p.a.</span></div>
      </div>
    </div>
    <div class="row mx-3 mx-md-4">
      <div class="calc__details-1st-column col-8 px-2 my-auto">
        <div><span>Zinsfreier Zeitraum</span>:</div>
      </div>
      <div class="calc__details-2nd-column col-4 px-0">
        <span
        ><strong>{getInterestFreeRates(), plural, one {30 Tage} other {{{ getInterestFreeRates() }} Raten}}</strong></span>
      </div>
    </div>
  </div>
  <div class="show-plans__disclaimer col-12 text-center">
    <span>Bonität vorausgesetzt. Diese Angaben entsprechen dem Rechenbeispiel gemäß PAngV. Es besteht ein gesetzliches Widerrufsrecht. Stand: Februar&nbsp;2024</span>
  </div>
</div>
<div class="show-plans__company-address show-plans__company-address--colors col-12 text-center">
  <product-hint></product-hint>
</div>
