import {Injectable} from '@angular/core';
import {Terms} from '@app/util/model/terms';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs';
import {BackendService} from '../backend/controller/backend.service';

@Injectable()
export class DefaultTermsService {

  readonly MAX_TIME_DIFF_SECONDS = 3 * 60 * 60;

  private defaultTerms: Terms;

  private lastUpdate: Date;

  constructor(private backendService: BackendService) {
  }

  public getDefaultTerms(): Observable<Terms> {
    if (this.defaultTerms && this.wasRecentlyUpdated()) {
      return of(this.defaultTerms);
    }
    return this.backendService.getDefaultTerms().pipe(map(next => {
      this.defaultTerms = next;
      this.lastUpdate = new Date();
      return next;
    }));
  }


  private wasRecentlyUpdated() {
    let timeDiffInSeconds = (new Date().getTime() - this.lastUpdate.getTime()) / 1000;
    return timeDiffInSeconds < this.MAX_TIME_DIFF_SECONDS;
  }
}
