import {Component} from '@angular/core';
import {environment} from '@env/environment';

@Component({
  selector: 'dummy-info-card',
  templateUrl: './dummy-info-card.component.html',
  styleUrls: ['dummy-info-card.component.css']
})
export class DummyInfoCardComponent {

  public readonly BRAND_NAME: string = environment.brandName;

  constructor() { }

}
