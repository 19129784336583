import {Injectable} from '@angular/core';
import {DateUtil} from '@app/util/static-services/date.util';
import {StringUtil} from '@app/util/static-services/string.util';
import {environment} from '@env/environment';
import {CookieService} from 'ngx-cookie-service';

export enum RouteRestriction {
  UNSET, PORTAL, PENDING
}

@Injectable()
export class UserAuthenticationService {

  readonly TOKEN_COOKIE = 'PA_CU_TOKEN';

  readonly ID_COOKIE = 'PA_CU_ID';

  readonly RESTRICTION_COOKIE = 'PA_RR';

  private _customerId: string;

  private _token: string;

  private _email: string;

  private _restriction: RouteRestriction;

  private domain = environment.domain;

  constructor(private cookieService: CookieService) {
    this.restriction = +this.tryAndGetFromCookies(this.RESTRICTION_COOKIE);
  }

  isAuthenticatedForPortal(): boolean {
    return this.isLoggedIn() && this.restriction === RouteRestriction.PORTAL;
  }

  isAuthenticatedForPending(): boolean {
    return this.isLoggedIn() && this.restriction === RouteRestriction.PENDING;
  }

  isLoggedIn(): boolean {
    return StringUtil.isNotBlank(this.token) && StringUtil.isNotBlank(this.customerId);
  }

  hasCustomerId(): boolean {
    return StringUtil.isNotBlank(this.customerId);
  }

  reset() {
    this.token = null;
    this.customerId = null;
    this.email = null;
    this.restriction = RouteRestriction.UNSET;
    this.resetCookies();
  }

  private resetCookies() {
    this.cookieService.delete(this.TOKEN_COOKIE);
    this.cookieService.delete(this.ID_COOKIE);
    this.cookieService.delete(this.RESTRICTION_COOKIE);
  }

  private tryAndGetFromCookies(cookieName: string): string {
    if (this.cookieService.check(cookieName)) {
      return this.cookieService.get(cookieName);
    }
    return null;
  }

  private setAuthCookie(name: string, value: string) {
    this.cookieService.set(name, value, DateUtil.addToDate(undefined, 1000 * 60 * 60), '/',
      this.domain, this.domain !== 'localhost');
  }

  get email(): string {
    return this._email;
  }

  set email(value: string | null) {
    this._email = value;
  }

  get customerId(): string {
    if (StringUtil.isEmptyOrNull(this._customerId)) {
      this._customerId = this.tryAndGetFromCookies(this.ID_COOKIE);
    }
    return this._customerId;
  }

  set customerId(value: string | null) {
    this._customerId = value;
    this.setAuthCookie(this.ID_COOKIE, value);
  }

  get token(): string {
    if (StringUtil.isEmptyOrNull(this._token)) {
      this._token = this.tryAndGetFromCookies(this.TOKEN_COOKIE);
    }
    return this._token;
  }

  set token(value: string | null) {
    this._token = value;
    this.setAuthCookie(this.TOKEN_COOKIE, value);
  }

  get restriction(): RouteRestriction {
    if (this._restriction === RouteRestriction.UNSET) {
      this._restriction = +this.tryAndGetFromCookies(this.RESTRICTION_COOKIE);
    }
    return this._restriction;
  }

  set restriction(value: RouteRestriction) {
    this._restriction = value;
    this.setAuthCookie(this.RESTRICTION_COOKIE, value.toString());
  }

}
