import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'iban'
})
export class IbanPipe implements PipeTransform {
  transform(val) {
    if (!val) {
      return val;
    }
    let iban = val.toString().toUpperCase();
    iban = iban.split(' ').join('');
    iban = iban.replace(/(.{4})/g, '$1 ');
    iban = iban.trim();
    return iban;
  }
}
