import {CommonModule} from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HttpLogInterceptor} from '@app/core/backend/interceptors/http-log.interceptor';
import {LocaleService} from './services/locale.service';
import {CookieService} from 'ngx-cookie-service';
import {UserAuthenticationService} from './backend/authentication/user-authentication.service';
import {BackendConnectorService} from './backend/controller/backend-connector.service';
import {BackendService} from './backend/controller/backend.service';
import {DefaultTermsService} from './services/default-terms.service';
import {PartnerShopsService} from './services/partner-shops.service';
import {SupportedCountriesService} from './services/supported-countries.service';

@NgModule({ imports: [CommonModule,
        RouterModule], providers: [
        BackendService,
        BackendConnectorService,
        UserAuthenticationService,
        SupportedCountriesService,
        PartnerShopsService,
        DefaultTermsService,
        CookieService,
        LocaleService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpLogInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] }
)
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
