import {Directive} from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {IbanUtil} from '@app/core/static/iban.util';

@Directive({
  selector: '[ibanValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: IbanValidator, multi: true}]
})
export class IbanValidator implements Validator {

  validator: ValidatorFn;

  constructor() {
    this.validator = this.ibanValidator();
  }

  validate(c: UntypedFormControl) {
    return this.validator(c);
  }

  ibanValidator(): ValidatorFn {
    return (c: UntypedFormControl) => {
      if (!c.value) {
        return null;
      }
      return IbanUtil.isValidIban(c.value) ? null : {ibanInvalid: true};
    };
  }
}