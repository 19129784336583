import {Injectable} from '@angular/core';
import {AddressInput} from '@app/util/model/address-input';
import {map, Observable, of} from 'rxjs';
import {BackendService} from '../backend/controller/backend.service';
import {environment} from '@env/environment';

@Injectable()
export class PartnerShopsService {

  readonly MAX_TIME_DIFF_SECONDS = 3 * 60 * 60;

  private partnerShops: ShopInfo[] = [];

  private lastUpdate: Date;

  private productType = environment.productType;

  constructor(private backendService: BackendService) {
  }

  public getSupportedCountries(): Observable<ShopInfo[]> {
    if (this.partnerShops.length > 0 && this.wasRecentlyUpdated()) {
      return of(this.partnerShops);
    }
    return this.backendService.getAllShops(this.productType).pipe(map(next => {
      this.partnerShops = next.partnerShops;
      this.lastUpdate = new Date();
      return this.partnerShops;
    }));
  }

  private wasRecentlyUpdated() {
    let timeDiffInSeconds = (new Date().getTime() - this.lastUpdate.getTime()) / 1000;
    return timeDiffInSeconds < this.MAX_TIME_DIFF_SECONDS;
  }

}

export class ShopInfo {
  locations: AddressInput[];
  shopUrl?: string;
  brandName: string;
}
