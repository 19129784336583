import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {SupportedCountriesService} from '@app/core';
import {
  SupportedCountriesResponse,
  SupportedCountryDetails
} from '@app/core/backend/response/supported-countries.response';
import {AddressInput} from '@app/util/model/address-input';

@Component({
  selector: 'address-input-component',
  templateUrl: './address-input.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]

})
export class AddressInputComponent implements OnInit {

  @Input() address: AddressInput;
  @Input() isSubmit: boolean;
  @Input() form: NgForm;
  @Input() focus: boolean;

  @Output() addressChange = new EventEmitter<AddressInput>();

  @ViewChild('address', {read: ElementRef}) searchElement: ElementRef;
  @ViewChild('streetInput', {read: ElementRef}) streetRef: ElementRef;
  @ViewChild('numberInput', {read: ElementRef}) housenumberRef: ElementRef;
  @ViewChild('zipInput', {read: ElementRef}) zipRef: ElementRef;
  @ViewChild('cityInput', {read: ElementRef}) cityRef: ElementRef;

  private supportedCountries: SupportedCountriesResponse;

  countryDetails: SupportedCountryDetails[] = [];

  constructor(private ngZone: NgZone,
              private supportedCountriesService: SupportedCountriesService,
              @Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit(): void {
    this.initSupportedCountries();
  }

  private initSupportedCountries() {
    this.supportedCountriesService.getSupportedCountries().subscribe(next => {
      this.supportedCountries = next;
      this.supportedCountries.address.forEach(
        c => this.countryDetails = this.countryDetails.concat([this.supportedCountries.countryDetails[c]]));
    });
  }

}
