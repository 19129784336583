import {Translation} from '@app/core/model/translation';

export const ResultCodes: Translation[] =
  [
    {
      id: 300,
      de: 'E-Mail-Adresse und Passwort stimmen nicht überein.\n Bitte überprüfen Sie Ihre Eingabe.',
      en: 'E-Mail-Address and Password doesn\'t match.\n Please check your input'
    },
    {
      id: 304,
      de: 'Sie versuchen sich gerade bei FINANCE A BIKE / Sofortkredit anzumelden. Bitte wechseln Sie zu dem Portal für Sofortkredit / FINANCE A BIKE: --URL--',
      en: 'You are currently trying to log-in to FINANCE A BIKE / Sofortkredit. Please go to the portal for Sofortkredit / FINANCE A BIKE: --URL--'
    }
  ];

