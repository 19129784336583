import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {BackendService, DefaultTermsService} from '@app/core';
import {PaymentPlan} from '@app/util/model/payment-plan';
import {Terms} from '@app/util/model/terms';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit {

  @Input() terms: Terms;

  @Output() annuitiesChange = new EventEmitter<PaymentPlan[]>();

  amount = 0;

  inputToggled: boolean;

  annuities: PaymentPlan[];

  loading = true;
  amountForm = new UntypedFormGroup({
    amountControl: new UntypedFormControl(this.amount,
      [Validators.required, Validators.min(0)])
  });

  constructor(private route: ActivatedRoute,
              private backendService: BackendService,
              private defaultTermsService: DefaultTermsService) {
  }

  private toggleInput() {
    this.inputToggled = !this.inputToggled;
  }

  ngOnInit(): void {
    if (this.terms) {
      this.initAnnuitiesFromTerms();
    } else {
      this.startFromDefaultTerms();
    }
  }

  refreshAnnuities() {
    this.loading = true;
    this.backendService.getAnnuitiesForAmount(this.amount).subscribe(next => {
      if (!this.terms) {
        this.terms = next.terms;
      }
      this.amountForm.controls['amountControl'].setValidators(
        [Validators.max(this.terms.limit), Validators.min(this.terms.minPurchaseAmount)]);

      this.annuities = next.annuities;
      this.annuitiesChange.emit(this.annuities);
      this.loading = false;
    });
  }

  submit() {
    this.amount = this.amountForm.controls['amountControl'].value;
    this.refreshAnnuities();
  }

  private startFromDefaultTerms() {
    this.defaultTermsService.getDefaultTerms().subscribe(next => {
      this.terms = next;
      this.initAnnuitiesFromTerms();
    });
  }

  private initAnnuitiesFromTerms() {
    this.amount = this.terms.limit / 2;
    this.amountForm.controls['amountControl'].setValue(this.amount);
    this.refreshAnnuities();
  }

}
