<div class="card shadow-sm">
  <div class="card-body pb-5">
    <h3>
      <strong>Shopfinder</strong>
    </h3>
    <div class="my-5">
      <img class="img-fluid" src="assets/icons/shopfinder.svg" width="48px" alt="shopfinder icon"/>
    </div>
    <p class="mt-3 mb-3">Mit {{ BRAND_NAME }} können Sie bei hunderten Partnerhändlern bezahlen.
      Finden Sie hier den Partnerhändler ganz in Ihrer Nähe.</p>
    <a class="par-card-link" (click)="goToShopFinder()">Shopfinder</a>
  </div>
</div>
