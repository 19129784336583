<div class="row" [class.gaussed]="loading">
  <div class="col-12">
    <div class="mt-4">
      <div class="row" *ngIf="!inputToggled">
        <div class="col-12 text-center calc__desired-amount-label">
          <strong>Wunschbetrag eingeben</strong>
        </div>
        <div class="col-12 text-center calc__chevron--spacing">
          <a (click)="toggleInput()">
            <span class="material-icons calc__chevron--size">expand_more</span>
          </a>
        </div>
      </div>
      <div class="row" *ngIf="!inputToggled">
        <div class="col-12 text-center my-0 calc__desired-amount">
          <a id="calc-desired-amount-link" (click)="toggleInput()">
            {{ amount | currencyPostfix }}
          </a>
          <hr class="text-center calc-desired-amount-underline"/>
        </div>
      </div>
      <div class="row" *ngIf="inputToggled">
        <div class="col-12 px-5">
          <form [formGroup]="amountForm" (ngSubmit)="submit();toggleInput()">
            <mat-form-field appearance="outline" class="w-100 btn-suffix">
              <mat-label>EUR</mat-label>
              <input matInput type="number" placeholder="EUR" class="calc__desired-amount"
                     name="amountControl" required focus max="{{terms.limit}}" min="{{terms.minPurchaseAmount}}"
                     formControlName="amountControl">
              <button matSuffix mat-raised-button color="accent" type="submit" class="calc__desired-amount-button"
                      [disabled]="!amountForm.valid">OK
              </button>
              <mat-hint *ngIf="terms?.limit">
                Max. {{ terms.limit| currencyPostfix }}
              </mat-hint>
              <mat-error *ngIf="amountForm.get('amountControl').hasError('min') && amountForm.touched"
              >
                Bitte geben Sie einen Betrag an.
              </mat-error>
              <mat-error
                  *ngIf="amountForm.get('amountControl').hasError('min') || amountForm.get('amountControl').hasError('max')"
              >
                Bitte geben Sie einen Betrag zwischen {{ terms?.minPurchaseAmount | currencyPostfix }} und
                {{ terms?.limit | currencyPostfix }} ein.
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
      <app-show-plans [terms]="terms" [annuities]="annuities"></app-show-plans>
    </div>
  </div>
</div>
<div class="text-center spinner" *ngIf="loading">
  <div class="spinner-container">
    <img src="assets/icons/spinner.svg" class="rotate-center" width="70" alt="Spinner">
    <div class="mt-5">
      <strong>Wir rechnen gerade,<br> bitte haben Sie einen Moment Geduld.</strong>
    </div>
  </div>
</div>
