<div class="card shadow-sm">
  <div class="card-body pb-5">
    <h3>
      <strong>Beispielrechner</strong>
    </h3>
    <div class="my-5">
      <img class="img-fluid" src="assets/icons/calculator.svg" width="48px" alt="calculator icon"/>
    </div>
    <p class="mt-3 mb-3">Probieren Sie unseren Beispielrechner aus und erfahren Sie,
      wie gering die Kosten einer Finanzierung mit {{ BRAND_NAME }} sind.</p>
    <a class="par-card-link" (click)="goToCalculator()">Jetzt rechnen</a>
  </div>
</div>
