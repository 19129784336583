<div class="card shadow-sm max-height">
  <div class="card-body pb-5">
    <h3>
      <strong>Weitere Informationen</strong>
    </h3>
    <div class="my-5">
      <img class="img-fluid" src="assets/icons/info.svg" width="48px" alt="info icon"/>
    </div>
    <p class="mt-3 mb-3">
      Sie haben Fragen zu dem {{ BRAND_NAME }}? Weitere Informationen finden Sie in dem Bereich FAQ.
    </p>
    <a class="par-card-link" href="https://sofortkredit.vwfs.de/faq.html">Zu den FAQ</a>
  </div>
</div>
