import {environment} from '@env/environment';
import {DateUtil} from './date.util';

enum LogLevel {
  INFO = 0, DEBUG = 1, WARN = 2, ERROR = 3
}

export class Logger {

  private logLevel: LogLevel = environment.production ? LogLevel.ERROR : LogLevel.INFO;

  constructor(private clazz: string) {
  }

  info(msg: string, ...optionalParams: any[]): void {
    this.log(msg, LogLevel.INFO, optionalParams);
  }

  debug(msg: string, ...optionalParams: any[]): void {
    this.log(msg, LogLevel.DEBUG, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]): void {
    this.log(msg, LogLevel.WARN, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]): void {
    this.log(msg, LogLevel.ERROR, optionalParams);
    throw new Error(this.getLogMessage(LogLevel.ERROR, msg, optionalParams));
  }

  private log(msg: string, level: LogLevel, params: any[]): void {
    if (level >= this.logLevel) {
      console.log(this.getLogMessage(level, msg, params));
    }
  }

  private getLogMessage(level: LogLevel, msg: string, params: any[]) {
    return LogLevel[level] + ' - ' + DateUtil.formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss') + ' - '
      + this.clazz + ': ' + msg + ' - ' + this.formatParams(params);
  }

  private formatParams(params: any[]) {
    let ret: string = params.join(',');

    if (params.some(p => typeof p === 'object')) {
      ret = '';
      try {
        for (let item of params) {
          ret += JSON.stringify(item) + ',';
        }
      } catch (e) {
        ret = '! could not stringify object !';
      }

    }

    return ret;
  }
}
