import {Component, EventEmitter, Output} from '@angular/core';
import {environment} from '@env/environment';

@Component({
  selector: 'shop-finder-component',
  templateUrl: './shop-finder-card.component.html',
  styleUrls: ['./card.component.css']
})
export class ShopFinderCardComponent {

  public readonly BRAND_NAME: string = environment.brandName;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  goToShopFinder() {
    this.clicked.emit();
  }
}
