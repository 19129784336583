import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'tan-input',
  templateUrl: 'tan-input.component.html',
  styleUrls: ['tan-input.component.css'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class TanInputComponent {

  @Input() phone: string;

  @Input() resent: boolean;

  @Input() tanModel: string[];

  @ViewChild('tan1', { read: ElementRef, static: true }) tan1: ElementRef;
  @ViewChild('tan2', { read: ElementRef, static: true }) tan2: ElementRef;
  @ViewChild('tan3', { read: ElementRef, static: true }) tan3: ElementRef;
  @ViewChild('tan4', { read: ElementRef, static: true }) tan4: ElementRef;
  @ViewChild('tan5', { read: ElementRef, static: true }) tan5: ElementRef;

  focus: boolean;

  constructor() {
  }

  backSpacePressed(event: any) {
    if (event.key === 'Backspace') {
      if (event.target.value === '') {
        this.focusPrevious(event);
      }
    }
    if ((event.key === 'Del' || event.key === 'Delete') && event.target.value === '') {
      this.focusPrevious(event);
    }
  }

  focusNext(event: any) {
    let index = event.target.getAttribute('data-index');
    if (this.isNumeric(event.target.value)) {
      let elem = this.getNextElement(parseInt(index, 10));
      if (elem) {
        elem.nativeElement.focus();
      }
    } else {
      this.tanModel[index - 1] = '';
    }
  }

  onPasteEvent(event: any) {
    let input = event.clipboardData.getData('text/plain');

    if (input.length !== 5 || !this.isNumeric(input)) {
      for (let i = 0; i < 5; i++) {
        this.tanModel[i] = '';
      }
      return;
    }
    for (let i = 0; i < 5; i++) {
      this.tanModel[i] = input.charAt(i);
    }
  }

  private focusPrevious(event: any) {
    let index = event.target.getAttribute('data-index');
    let elem = this.getPreviousElement(parseInt(index, 10));
    if (elem) {
      elem.nativeElement.focus();
    }
  }

  private isNumeric(value: string): boolean {
    let regex = /^\d+$/;
    return regex.test(value.trim());
  }

  private getPreviousElement(index: number): any {
    if (index === 1) {
      return null;
    } else if (index === 2) {
      return this.tan1;
    } else if (index === 3) {
      return this.tan2;
    } else if (index === 4) {
      return this.tan3;
    } else if (index === 5) {
      return this.tan4;
    }
  }

  private getNextElement(index: number): any {
    if (index <= 0) {
      return this.tan1;
    }
    if (index === 1) {
      return this.tan2;
    } else if (index === 2) {
      return this.tan3;
    } else if (index === 3) {
      return this.tan4;
    } else if (index === 4) {
      return this.tan5;
    } else if (index === 5) {
      return null;
    }
  }

}
