<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 offset-md-3">
      <nav-logo></nav-logo>
      <h1>Ein Fehler ist aufgetreten</h1>
      <p>Das hätte vermutlich nicht passieren sollen. Bitte versuchen Sie es noch einmal oder
        wenden Sie sich an <a [href]="customerServiceMailTo">{{ getCustomerServiceMail() }}</a></p>
    </div>
  </div>
</div>
