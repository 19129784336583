import {Injectable} from '@angular/core';
import {LocaleService} from '@app/core';
import {Logger} from '@app/util/static-services/logger';
import {ResultCodes} from '@app/core/services/resultcode';
import {Translation} from '@app/core/model/translation';

@Injectable({
  providedIn: 'root'
})
export class ResultcodeTranslationService {

  private logger: Logger = new Logger(ResultcodeTranslationService.name);

  private resultCodes: Translation[] = ResultCodes;

  constructor(private localeService: LocaleService) {
  }

  public translate(code: number, overrides?: Translation[]) {
    let translation: Translation;
    if (overrides) {
      translation = overrides.find(p => p.id === code);
    }
    if (!translation) {
      translation = this.resultCodes.find(p => p.id === code);
    }
    if (!translation) {
      this.logger.warn('No translation found for resultCode "' + code + '".');
      translation = this.resultCodes.find(p => p.id === 1000);
    }

    if (this.localeService.isLocaleEn()) {
      return translation.en;
    }
    return translation.de;
  }
}
