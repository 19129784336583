import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BackendService} from '@app/core';
import {AnnuitiesResponse} from '@app/core/backend/response/annuities.response';
import {PaymentPlan} from '@app/util/model/payment-plan';
import {Terms} from '@app/util/model/terms';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';

@Component({
  selector: 'change-rate',
  templateUrl: './change-rate.component.html',
  styleUrls: ['./change-rate.component.css']
})
export class ChangeRateComponent implements OnInit {

  @Input() terms: Terms;

  @Input() annuities: PaymentPlan[];
  @Input() annuities$: Observable<AnnuitiesResponse>;

  @Input() amount: number;
  @Input() currentPlanIndex: number;
  @Input() desiredDuration: number;
  @Input() interestFreeRates: number;
  @Input() interestFreeRatesMerchant: number;
  @Input() isOverlay: boolean;


  @Output() changeEmitter = new EventEmitter<number>();
  @Output() planEmitter = new EventEmitter<PaymentPlan>();
  @Output() loadedEmitter = new EventEmitter();

  currentPlan: PaymentPlan;

  loading = true;

  constructor(private backendService: BackendService,
              private cookieService: CookieService) {
  }

  ngOnInit() {
    if (this.annuities$) {
      this.annuities$.subscribe(next => {
        this.annuities = next.annuities;
        this.setTerms(next.terms);
        this.initFromAnnuities();
      });
    } else if (this.annuities) {
      this.initFromAnnuities();
    } else {
      this.backendService.getAnnuitiesForAmount(this.amount).subscribe(next => {
        this.annuities = next.annuities;
        this.terms = next.terms;
        this.initFromAnnuities();
      });
    }
  }

  private initFromAnnuities() {
    this.initIndex();
    this.currentPlan = this.annuities[this.currentPlanIndex];
    this.amount = this.currentPlan.nominalAmount;
    this.finishLoading();
  }

  setRate() {
    this.changeEmitter.emit(this.currentPlan.duration);
    this.planEmitter.emit(this.currentPlan);
  }

  private setTerms(terms: Terms) {
    if (!this.terms) {
      this.terms = terms;
    }
  }

  private initIndex() {
    let duration = this.desiredDuration || +this.cookieService.get('desiredDuration') || 24;

    let index = this.annuities.findIndex(p => p.duration === duration);
    if (index >= 0) {
      this.currentPlanIndex = index;
    }
    if (!this.currentPlanIndex || this.currentPlanIndex < 0) {
      this.currentPlanIndex = 0;
    }

  }

  private finishLoading() {
    this.loading = false;
    this.loadedEmitter.emit();
  }
}
