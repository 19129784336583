import {Location} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '@env/environment';

@Component({
  selector: 'nav-logo',
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.css']
})
export class NavLogoComponent implements OnInit {

  @Input() hasBackButton;

  @Output() backClicked = new EventEmitter<boolean>();

  readonly marketingUrl = environment.marketingUrl;

  constructor() {
  }

  ngOnInit() {
  }

  goBack() {
    this.backClicked.emit(true);
  }
}
