<div class="row">
  <div class="col-12">
    <mat-form-field appearance="outline">
      <input autocomplete="off" matInput #tan1="ngModel" id="tan1" type="tel" pattern="[0-9]{1}" maxlength="1" required
             (paste)="onPasteEvent($event)" [(ngModel)]="tanModel[0]" name="tan1"
             (focus)="focus = true; tanModel[0] = ''" (blur)="focus = false"
             (input)="focusNext($event)" data-index="1">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input autocomplete="off" matInput #tan2="ngModel" id="tan2" type="tel" pattern="[0-9]{1}" maxlength="1" required
             (paste)="onPasteEvent($event)" [(ngModel)]="tanModel[1]" name="tan2"
             (focus)="focus = true; tanModel[1] = ''" (blur)="focus = false"
             (input)="focusNext($event)" (keydown)="backSpacePressed($event)" data-index="2">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input autocomplete="off" matInput #tan3="ngModel" id="tan3" type="tel" pattern="[0-9]{1}" maxlength="1" required
             (paste)="onPasteEvent($event)" [(ngModel)]="tanModel[2]" name="tan3"
             (focus)="focus = true; tanModel[2] = ''" (blur)="focus = false"
             (input)="focusNext($event)" (keydown)="backSpacePressed($event)" data-index="3">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input autocomplete="off" matInput #tan4="ngModel" id="tan4" type="tel" pattern="[0-9]{1}" maxlength="1" required
             (paste)="onPasteEvent($event)" [(ngModel)]="tanModel[3]" name="tan4"
             (focus)="focus = true; tanModel[3]= ''" (blur)="focus = false"
             (input)="focusNext($event)" (keydown)="backSpacePressed($event)" data-index="4">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input autocomplete="off" matInput #tan5="ngModel" id="tan5" type="tel" pattern="[0-9]{1}" maxlength="1" required
             (paste)="onPasteEvent($event)" [(ngModel)]="tanModel[4]" name="tan5"
             (focus)="focus = true; tanModel[4]= ''" (blur)="focus = false"
             (keydown)="backSpacePressed($event)" data-index="5">
    </mat-form-field>
  </div>
  <div class="col-12 text-center c2-hint"
       *ngIf="(((tan1.errors?.required || tan1.invalid) && (tan1.dirty || tan1.touched)) ||
                ((tan2.errors?.required || tan2.invalid) && (tan2.dirty || tan2.touched)) ||
                ((tan3.errors?.required || tan3.invalid) && (tan3.dirty || tan3.touched)) ||
                ((tan4.errors?.required || tan4.invalid) && (tan4.dirty || tan4.touched)) ||
                ((tan5.errors?.required || tan5.invalid) && (tan5.dirty || tan5.touched)))">
    <mat-error class="text-center">Bitte füllen Sie alle Stellen der TAN aus.</mat-error>
  </div>
</div>
