export class StringUtil {

  public static isEmptyOrNull(toTest: string): boolean {
    return toTest === undefined || toTest === null || toTest.length === 0;
  }

  public static isNotBlank(toTest: string): boolean {
    return !this.isEmptyOrNull(toTest);
  }

  public static zeroPad(toPad: string, length: number): string {
    if (toPad.length === length) {
      return toPad;
    }

    while (toPad.length < length) {
      toPad = '0' + toPad;
    }
    return toPad;
  }

}