import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Logger} from '@app/util/static-services/logger';
import {environment} from '@env/environment';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs';
import {RouteRestriction, UserAuthenticationService} from '../authentication/user-authentication.service';
import {BackendRequest} from '../requests/backend.request';
import {BackendResponse} from '../response/backend.response';
import {BackendError} from './backend.error';
import {RequestType} from './request-type.enum';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class BackendConnectorService {

  private logger: Logger = new Logger(BackendConnectorService.name);

  requestUrl: string = environment.backendUrl;

  constructor(private http: HttpClient,
              private authenticationService: UserAuthenticationService,
              private router: Router) {
  }

  callBackend<REQUEST, RESPONSE>(type: RequestType, requestDetails: REQUEST): Observable<RESPONSE> {
    let request: BackendRequest = this.createRequest(type, requestDetails);
    return this.http.post<BackendResponse>(this.requestUrl, request, httpOptions).pipe(
      tap(next => {
        if (type === RequestType.LOGOUT) {
          this.authenticationService.reset();
          this.router.navigate(['/portal/signed-out']);
        }
        if (this.isAuthTypeRequest(type)) {
          this.setAuthenticationFromResponse(next.data);
        }

        this.setRouteRestrictionIfApplicable(type, next);
      }),
      map(next => {
        if (next.success && !next.validationErrorDetails) {
          return next.data;
        } else {
          throw new BackendError(next.resultCode, next.description || '', next.validationErrorDetails);
        }
      }),
      catchError(err => {
        if (err.name && err.name === 'HttpErrorResponse') {
          this.logger.error('HttpErrorResponse for request type ' + RequestType[type], err, requestDetails);
          this.router.navigate(['/not-reachable']);
          return of(null);
        }
        throw err;
      }));
  }

  private createRequest<REQUEST>(type: RequestType, requestDetails: REQUEST) {
    let request = new BackendRequest();
    request.type = RequestType[type];
    request.data = requestDetails;
    this.setFromAuthentication(type, request);
    return request;
  }

  private setAuthenticationFromResponse(next: any) {
    if (next && next.customerId) {
      this.authenticationService.customerId = next.customerId;
    }
    if (next && next.token) {
      this.authenticationService.token = next.token;
    }
  }

  private setFromAuthentication(type: RequestType, request: BackendRequest) {
    const resetAuthTypes = [RequestType.URL_REFERRAL_INIT, RequestType.CREATE_SESSION];
    if (resetAuthTypes.indexOf(type) >= 0) {
      this.logger.info('resetting authentication');
      this.authenticationService.reset();
      return;
    }
    this.logger.info('setting authentication for request');
    if (this.authenticationService.customerId) {
      request.customerId = this.authenticationService.customerId;
    }
    if (this.authenticationService.token) {
      request.token = this.authenticationService.token;
    }
  }

  private isAuthTypeRequest(type: RequestType) {
    return type === RequestType.URL_REFERRAL_INIT
      || type === RequestType.REPLACE_TOKEN || type === RequestType.URL_REFERRAL_TOPUP
      || type === RequestType.CREATE_SESSION || type === RequestType.STORE_DATA
      || type === RequestType.RISK_CHECK;
  }

  private setRouteRestrictionIfApplicable(type: RequestType, next: any) {
    if (next.success && type === RequestType.RISK_CHECK) {
      this.authenticationService.restriction = RouteRestriction.PENDING;
    }
  }
}
