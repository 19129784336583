export const environment = {
  production: false,
  mode: 'dev',
  placesApiKey: 'AIzaSyAM5xfoh5V8brYtzEwiIFGgLhId5Mu3CAM',

  shortName: 'fab',
  domainName: 'financeabike.de',
  domain: '.dev-financeabike.de',

  loggingUrl: 'https://backend.dev-financeabike.de/v1/log/write',
  backendUrl: 'https://backend.dev-financeabike.de/backend/request',
  mainUrl: 'https://portal.dev-financeabike.de',
  marketingUrl: 'https://www.financeabike.de',
  triggerSimulationUrl: 'https://backend.dev-financeabike.de/backend/urlreferral/trigger_callback',
  merchantTermsUrl: 'https://partner.financeabike.de/assets/FAB_TAC.pdf',

  enableMarketingEnrollment: true,
  productType: 'FINANCEABIKE',
  brandName: 'FINANCE A BIKE',

  counterProductUrl: 'https://portal.c2-skdev.de/#/portal/login'
};
