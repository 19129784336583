export class Terms {
  currency: string;
  limit: number;
  nominalInterest: number;
  effectiveInterest: number;
  interestFreeRatesC2: number;
  productType: string;
  durations: number[];
  minPurchaseAmount: number;
}
