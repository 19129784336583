import {Injectable} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {BackendService} from '../backend/controller/backend.service';
import {SupportedCountriesResponse} from '../backend/response/supported-countries.response';

@Injectable()
export class SupportedCountriesService {

  private supportedCountries: SupportedCountriesResponse;

  constructor(private backendService: BackendService) {
  }

  public getSupportedCountries(): Observable<SupportedCountriesResponse> {
    if (this.supportedCountries) {
      return of(this.supportedCountries);
    }
    return this.backendService.getSupportedCountries().pipe(map(next => {
      this.supportedCountries = next;
      return next;
    }));
  }


}
