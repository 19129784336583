import {Component, EventEmitter, Output} from '@angular/core';
import {environment} from '@env/environment';

@Component({
  selector: 'app-calculator-card',
  templateUrl: './calculator-card.component.html',
  styleUrls: ['./card.component.css']
})
export class CalculatorCardComponent {

  public readonly BRAND_NAME: string = environment.brandName;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {

  }

  goToCalculator() {
    this.clicked.emit();
  }

}
