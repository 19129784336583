import {Inject, Injectable, LOCALE_ID} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(@Inject(LOCALE_ID) public locale: string) {
  }

  isLocaleEn(): boolean {
    return this.locale && this.locale.toLowerCase().indexOf('en') >= 0;
  }
}
