<footer class="p-2">
  <div class="container text-center">
    <div class="row">
      <div class="col-12 align-self-center">
        <a routerLink="/legal-notice" target="_blank">Impressum</a>
        &nbsp;|&nbsp;
        <a routerLink="/data-privacy" target="_blank">Datenschutzinformation</a>
        &nbsp;|&nbsp;
        <a routerLink="/terms-of-use" target="_blank">Nutzungsbedingungen</a>
      </div>
    </div>
  </div>
</footer>