import {CurrencyPipe, getCurrencySymbol} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'currencyPostfix'
})
export class CurrencyPostfixPipe extends CurrencyPipe implements PipeTransform {

  transform(value: any, currencyCode = 'EUR', display = 'symbol', digitsInfo = '1.2-2', locale = 'de-DE'): any {
    if (value === undefined || value === null) {
      return '';
    }
    const currencySymbol = getCurrencySymbol(currencyCode, 'wide');
    let transformed = super.transform(value, currencyCode, display, digitsInfo, locale);
    transformed = transformed.replace(currencySymbol, '');
    return transformed + '' + currencySymbol;
  }

}
