<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 offset-md-3">
      <nav-logo></nav-logo>
      <h1>404</h1>
      <h2>Seite konnte nicht gefunden werden.</h2>
      <p>Sie sind vermutlich nicht da gelandet, wo Sie eigentlich hin wollten. Probieren Sie es am besten noch einmal
        von der Startlinie: <a routerLink="/onboarding/main">Start</a></p>
    </div>
  </div>
</div>
