<div class="row my-4 text-left">
  <div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 text-center align-self-center">
    <button mat-icon-button *ngIf="hasBackButton" (click)="goBack()" class="text-primary my-auto">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <a class="navbar-brand mx-auto" [href]="marketingUrl">
      <div class="logo"></div>
    </a>
  </div>
</div>
