import {StringUtil} from '@app/util/static-services/string.util';

export class AddressInput {

  street: string;
  housenumber: string;
  zip: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;


  public isComplete(): boolean {
    return StringUtil.isNotBlank(this.street) && StringUtil.isNotBlank(this.housenumber) && StringUtil.isNotBlank(
      this.city) && StringUtil.isNotBlank(this.zip) && StringUtil.isNotBlank(this.country);
  }
}