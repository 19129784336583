<div class="row text-center">
  <div class="col-12">
    <div class="row">
      <div class="col-8 pr-sm-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Straße</mat-label>
          <input autocomplete="address-line1" matInput #streetInput="ngModel" id="streetInput" type="text"
                 placeholder="Straße"
                 [(ngModel)]="address.street" name="street" required>
        </mat-form-field>
      </div>
      <div class="col-4 pl-sm-0">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Nr.</mat-label>
          <input autocomplete="address-line2" matInput #numberInput="ngModel" id="numberInput" type="text"
                 placeholder="Nr."
                 [(ngModel)]="address.housenumber" name="housenumber" required>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-4 pr-sm-0">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>PLZ</mat-label>
          <input autocomplete="postal-code" matInput #zipInput="ngModel" id="zipInput" type="text" placeholder="PLZ"
                 [(ngModel)]="address.zip" name="zip" required>
        </mat-form-field>
      </div>
      <div class="col-8 pl-sm-1">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Stadt</mat-label>
          <input autocomplete="address-level2" matInput #cityInput="ngModel" id="cityInput" type="text"
                 placeholder="Stadt"
                 [(ngModel)]="address.city" name="city" required>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="col-12">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Land</mat-label>
      <mat-select autocomplete="country" #countrySelect="ngModel" id="countrySelect" [(ngModel)]="address.country"
                  name="country"
                  required placeholder="Land">
        <mat-option *ngFor="let details of countryDetails" value="{{details.iso3166Alpha2}}">
          <span>{{ details.deName }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12">
    <button type="submit" class="w-100" mat-raised-button color="accent" [disabled]="!form.form.valid">OK
    </button>
  </div>
</div>
