import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpLogInterceptor implements HttpInterceptor {

  readonly prod = environment.production;
  readonly backendUrl = environment.backendUrl;

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const restUrlIndex = request.url.indexOf(this.backendUrl);
    if (this.prod) {
      return next.handle(request);
    }

    return this.interceptAndLog(request, next);
  }

  private interceptAndLog(request: HttpRequest<any>, next: HttpHandler) {
    const endpoint = request.url.substring(this.backendUrl.length);
    console.log('REQUEST ' + endpoint + '\n', request.body);
    return next.handle(request).pipe(
      tap((event) => {
          if (event && event instanceof HttpResponse) {
            console.log('RESPONSE\n', event.body);
          }
        }
      ));
  }
}
