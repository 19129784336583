import {Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './not-reachable.component.html'
})
export class NotReachableComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
