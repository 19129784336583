export class StringUtil {

  public static isEmptyOrNull(toTest: string): boolean {
    return toTest === undefined || toTest === null || toTest.length === 0;
  }

  public static isNotBlank(toTest: string): boolean {
    return !StringUtil.isEmptyOrNull(toTest);
  }

  public static isAnyEmptyOrBlank(...toTest: string[]): boolean {
    let hasCandidate = false;
    return toTest.some(p => StringUtil.isEmptyOrNull(p));
  }

}
