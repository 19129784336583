import { SafariSafeDatePipe } from '@app/shared/pipes/safari-safe-date.pipe';
import {DateInput} from '../model/date-input';

export class DateUtil {

  public static getEndOfMonth(date: Date) {
    let newDate = new Date(date);
    return new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
  }

  public static formatDateInput(input: DateInput): string {
    let date = new Date(input.year, input.month - 1, input.day);
    return DateUtil.format(date);
  }

  public static addToDate(date = new Date(), toAdd: number) {
    return new Date(date.getTime() + toAdd);
  }

  public static format(date: Date): string {
    return this.formatDate(date, 'yyyy-MM-dd');
  }

  public static formatDate(date: Date, format: string): string {
    let datepipe = new SafariSafeDatePipe('de');
    return datepipe.transform(date, format);
  }

  public static checkMinAge(birthDate: Date): boolean {
    let firstAsUTC = Date.UTC(birthDate.getFullYear(), birthDate.getMonth(), birthDate.getDay());
    let dateNow = new Date();
    let secondAsUTC = Date.UTC(dateNow.getFullYear() - 18, dateNow.getMonth(), dateNow.getDay());
    return firstAsUTC < secondAsUTC;
  }

  public static makeSafariValid(date: any): Date {
    let safariValidDate = date.toString().replace(' ', 'T');
    return new Date(safariValidDate);
  }

  public static getDateWithoutTime(date: any): Date {
    let dateWoTime = date.toString().substring(0, 10);
    return new Date(dateWoTime);
  }

  public static isValidInputDate(year: number, month: number, day: number): boolean {
    let date = new Date(year, month, day);
    year = +year;
    month = +month;
    day = +day;
    // Month is zero indexed, e.g. January === 0
    return date.getFullYear() === year && date.getMonth() === (month) && date.getDate() === day;
  }

}
