import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'text-input-component',
  templateUrl: './text-input.component.html',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]

})
export class TextInputComponent implements OnInit {

  @Input() input: string;

  @Input() isSubmit: boolean;

  @Input() form: NgForm;

  @Input() placeholder: string;

  @Output() inputChange = new EventEmitter<string>();

  @Input() focus: boolean;

  @ViewChild('inputInput', { read: ElementRef, static: true }) inputRef: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
    if (focus) {
      this.inputRef.nativeElement.focus();
    }
  }

  submit(): void {
    if (this.isSubmit) {
      this.inputChange.emit(this.input);
    }
  }
}
