import {Component} from '@angular/core';

@Component({
  selector: 'required-disclaimer',
  templateUrl: './required.disclaimer.component.html',
  styleUrls: ['./required.disclaimer.component.css']
})
export class RequiredDisclaimerComponent {

}
