<google-map
    #map
    mapId="1473b0012b19b4cd"
    height="{{screenHeight + 'px'}}"
    width="{{screenWidth + 'px'}}"
    [options]="options"
    [zoom]="7"
    [center]="center">


  @for (m of markers; track m) {
    <map-advanced-marker
        #marker="mapAdvancedMarker"
        [position]="m.position"
        (mapClick)="onMarkerClicked(marker, m)">
    </map-advanced-marker>
  }
  <map-info-window>
    <div *ngIf="openedMarker">
      <b>{{ openedMarker.label.text }}</b>
      <br>{{ openedMarker.street }}
      <br>{{ openedMarker.city }}
      <div *ngIf="openedMarker.url">
        <br>
        <a (click)="openLink(openedMarker.url)">{{ getDisplayUrl(openedMarker.url) }}</a>
      </div>
    </div>
  </map-info-window>
</google-map>