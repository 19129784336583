<mat-form-field appearance="outline" class="w-100" [class.btn-suffix]="isSubmit">
  <mat-label>IBAN</mat-label>
  <input matInput #ibanInput="ngModel" id="ibanInput" type="text" placeholder="IBAN" [ngModel]="ibanModel | iban"
         (ngModelChange)="ibanModel=$event" name="iban" required focus>
  <button *ngIf="isSubmit" matSuffix mat-raised-button color="accent" (click)="submit()" [disabled]="!form.form.valid">
    OK
  </button>
  <mat-error *ngIf="ibanInput.errors?.required && (ibanInput.dirty || ibanInput.touched)">
    Bitte geben Sie eine IBAN an.
  </mat-error>
</mat-form-field>